.header-border {
  border-bottom: 5px solid #0a2d44;
  margin: 0 0 0 0.5rem;
}
.mobile-menu {
  padding: 0.5rem 1rem;
}
.dark-color {
  color: #0a2d44 !important;
  font-weight: 500 !important;
  font-family: var(--family-label);
}

.header-logo {
  display: flex;
  align-items: center;
  /* gap: 20px; */
}

div.MuiToolbar-root.MuiToolbar-regular {
  justify-content: space-between;
  padding: 0rem 0.7rem 0rem 1rem;
}

.header-text {
  color: var(--color-primary);
  font-size: var(--fs-subtitle);
  font-family: var(--family-label);
  margin: 0;
}

.header-sub-text {
  color: var(--color-black);
  opacity: 0.8;
  font-size: var(--fs-lg);
  font-weight: 500;
  font-family: var(--family-label);
}

.links-react-router {
  text-decoration: none;
}

.links-react-router:hover {
  text-decoration: none;
}

.icon-menuitem {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1rem;
}

.profile-icon {
  /* border: 1px solid grey; */
  /* padding: 0.5rem; */
  background-color: #f5f5f5;
  border-radius: 3rem;
  padding: 3px 0px 3px 3px;
  font-family: var(--family-label);
}

.settings-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
}

.accordian-items {
  box-shadow: none !important;
}

.accordian-items
  div.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters,
.accordian-items div.MuiAccordionSummary-content.Mui-expanded {
  margin-right: 0.5rem !important;
}

.accordian-items div.MuiButtonBase-root.MuiAccordionSummary-root {
  min-height: 30px;
}

.accordian-items div.MuiAccordionDetails-root {
  padding: 0 0 1rem 6rem;
}

.accordian-items div.MuiAccordionDetails-root a:hover {
  text-decoration: none;
}
.header-notification {
  font-size: 3rem !important;
  color: var(--color-primary);
  cursor: pointer;
}
.layout-notification {
  font-size: 2rem !important;
  color: var(--color-primary);
  cursor: pointer;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  /* position: absolute;
  left: 0;
  top: -1px;
  right: 0;
   height: 1px;  */
  content: none !important;
  /* opacity: 1;
  
  background-color: rgba(0, 0, 0, 0.12); 
   
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
}
