.login {
  height: 100%;
}

.first-section {
  background-color: #fff;
  height: 100%;
}

.section-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(138 142 148 / 5%);
  border-radius: 10px;
}
.trackYourShipment {
  width: 28%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 720px) {
  .trackYourShipment {
    padding: 2rem;
    width: 100%;
  }
}
.login-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  text-align: center;
  color: #282828;
}

.login-mid-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #949ca9;
  text-align: center;
}

.form-style {
  width: 460px;
  height: 22px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #949ca9;
}

.login-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 460px;
  height: 52px;
  background-color: #0a2d44;
  border-radius: 6px;
}

.forget-password-div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.forget-link {
  text-transform: capitalize;
  color: #009ef7;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
}

.mid-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.line {
  width: 207px;
  height: 0px;
  border: 1px solid #b3b3b3;
}

.or {
  padding-inline: 15px;
}

.google-signup {
  border: 1px solid #b3b3b3;
  padding: 0.5rem;
}

.google-img {
  width: 20px;
  height: 20px;
}

.google-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
  padding-left: 5px;
  text-decoration: none;
}

.account {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
}

.color-signup {
  color: #009ef7;
}

.margin-div {
  margin-top: 0.5rem;
}

.span-error {
  opacity: 1;
  color: red;
  font-family: 'siena-light';
  font-size: 1rem;
}

.abc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.backgimgForTrackShipment {
  background: url('/public/assets/login_image_trackMyshipment.svg');
  background-size: cover;
  height: 100vh;
  padding: 0 !important;
  width: 100%;
  filter: blur(0.3rem);
  /* filter: blur(1.5rem); */
}

/* ------------------ */
/* .second-section {
  background: url('/public/assets/login_image.jpg');
  background-size: cover;

  background-repeat: no-repeat;
  height: 100%;
  padding: 0 !important;
  position: relative;
} */

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-image {
  width: 100%;
  height: 100%;
  /* display: block; */
}

.overlay-image {
  position: absolute;
  top: 82%;
  left: 35%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  width: 55%;
  height: auto;
}

.insight-font {
  font-size: var(--fs-md);
}

.line-second-section {
  width: 305px;
  height: 0px;
  border: 1px solid #b3b3b3;
}

.login-background-opacity {
  /* background: #1c3a66; */
  height: 100%;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-box {
  width: 50%;
  text-align: center;
}

.text-box p {
  color: #fff;
}

.read-btn {
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  padding: 0.2rem 0.8rem;
  background-color: transparent;
  color: #fff;
}
.refresh-button-track {
  background: none;
  border: none;
  color: #236c9c;
  cursor: pointer;
}

.text_filedStyle #captchaTrack {
  background-color: #fff !important;
  margin-top: 1px;
}
.text_filedStyle #captchaTrack-helper-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Poppins', 'Open Sans', sans-serif;
  margin-top: 0px !important;
}

.cancelIcon {
  color: #ffffff !important;
  font-size: 2em !important ;
  /* background-color: #1084f1 !important; */
  border-radius: 2em !important;
}

.cancelIcon:hover {
  color: rgb(24, 75, 109) !important;
  font-size: 2em !important;
  background-color: transparent !important;
  /* border-radius: 0em !important; */
}
.button-track .css-889gxc-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  background-color: #f5f5f594;
  color: rgb(0 0 0 / 78%);
}
